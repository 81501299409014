import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JsonApiService } from "@app/core/services/json-api.service";
import { Observable } from "rxjs/Observable";
import { IResponseMessage } from "@app/models/responsemessage.model";
import { Config } from "@app/config/config";
import { CreateMemberModel } from "@app/models/member/createmember.model";
import { EditMemberModel } from "@app/models/member/editmember.model";
import { EditMemberBuySellLimitModel } from '@app/models/member/editbuyselllimit.model';

const config = new Config();
@Injectable()
export class MemberService {
    readonly rootUrl = config.webAPIUrl + '/api';

    constructor(private jsonApiService: JsonApiService, private http: HttpClient) {
    }

    memberGetProfile(): Observable<IResponseMessage> {
        return this.http.get<IResponseMessage>(this.rootUrl + '/member/membergetprofile/');
    }
}
