import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from "@angular/http";
import { BehaviorSubject } from "rxjs";
import { JsonApiService } from "@app/core/services/json-api.service";
import { CreateMt4AccountModel } from '@app/models/createmt4account.model';
import { SubscriptionPackage } from '@app/models/subscriptionpackage.model';
import { IResponseMessage } from "@app/models/responsemessage.model";
import { Observable } from "rxjs/Observable";
import { Config } from "@app/config/config";
import { ChangePasswordModel } from "@app/models/changepassword.model";
import { Router } from "@angular/router";

const config = new Config();
const defaultUser = {
  username: "Member"
}
@Injectable()
export class UserService {
  readonly rootUrl = config.webAPIUrl;
  public user$ = new BehaviorSubject({ ...defaultUser });
  public user = { userName: null }
  isLoggedIn: boolean = false;
  private router: Router;

  constructor(private jsonApiService: JsonApiService, private http: HttpClient) {
    //this.jsonApiService.fetch("/user/login-info.json").subscribe(this.user$)
  }

  public logout() {
    localStorage.removeItem(this.rootUrl + 'goldPro_memberToken');
    localStorage.removeItem(this.rootUrl + 'GoldPro_Member');
    this.isLoggedIn = false;
    this.user$.next({ ...defaultUser })
  }

  userAuthentication(userName, password) {
    var data = "username=" + userName + "&password=" + password + "&grant_type=password&login_type=password&access_portal=GoldPro Member Web Portal";
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' });
    this.user.userName = userName;

    localStorage.setItem(this.rootUrl + 'GoldPro_Member', JSON.stringify(this.user));
    this.isLoggedIn = true;
    return this.http.post(this.rootUrl + '/token', data, { headers: reqHeader });
  }

  getUserClaims() {
    return this.http.get(this.rootUrl + '/api/authentication/GetUserClaims');
  }

  resetPassword(username: string): Observable<object> {
    var data = "username=" + username;
    return this.http.get<IResponseMessage>(this.rootUrl + '/api/user/memberforgotpassword?' + data);
  }

  changePassword(changePasswordModel: ChangePasswordModel): Observable<object> {
    return this.http.post(this.rootUrl + '/api/user/changepassword', changePasswordModel, {});
  }

  isUserLoggedIn() {
    return this.isLoggedIn;
  }

  setIsUserLoggedIn(isLoggedIn: boolean) {
    this.isLoggedIn = isLoggedIn;
  }

  IsAuthenticated() {
    return this.http.get(this.rootUrl + '/api/account/isauthenticated');
  }
}
