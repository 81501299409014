import {Component, OnInit} from '@angular/core';
import { UserService } from '@app/core/services/user.service';
import { LayoutService } from '@app/core/services/layout.service';
import { Config } from "@app/config/config";
const config = new Config();

@Component({

  selector: 'sa-login-info',
  templateUrl: './login-info.component.html',
})
export class LoginInfoComponent implements OnInit {
  public goldProUser={userName:null}
  readonly rootUrl = config.webAPIUrl;
  constructor(
    public us: UserService,
              private layoutService: LayoutService) {
  }

  ngOnInit() {
    this.goldProUser=JSON.parse(localStorage.getItem(this.rootUrl+'GoldPro_Member'));
  }

  toggleShortcut() {
    this.layoutService.onShortcutToggle()
  }

}
