export class Config {
    //PROD
    webAPIUrl = 'https://api.cpmgg.com.sg/'
    signalRUrl = 'https://api.cpmgg.com.sg:5008/signalr'

    //UAT 
    //webAPIUrl = 'https://api.cpmgg.com.sg/'
    //signalRUrl = 'https://api.cpmgg.com.sg:5008/signalr'
    // webAPIUrl = 'http://uat.api.cpmgg.com.sg/'
    // signalRUrl = 'http://uat.pricing.cpmgg.com.sg:5007/signalr'

    //SIT
    // webAPIUrl = 'http://sit.goldpro.api.stoneinno.com/'
    // signalRUrl = 'http://sit.pricing.cpmgg.com.sg:5006/signalr'

    //LOCAL
    // webAPIUrl = 'http://localhost:63670/'
    // signalRUrl = 'http://127.0.0.1:8084/signalr'
    singleDeviceSignInCheckingTimeinSecond = 8;
    signalRProxyName = 'PricingBroadcasterHub'
    signalRNotifySpotPrice = 'NotifySpotPrice'
    signalRNotifyProductPrice = 'NotifyProductPrice'
}
