import { StorageService } from "./storage.service";
import {
  AuthTokenFactory,
  AuthTokenService
} from "./auth-token.service";
import { AuthService } from "./auth.service";
import { TokenInterceptor } from "./token.interceptor";
import { JsonApiService } from "./json-api.service";
import { UserService } from "./user.service";
import { CountryService } from "./country.service";
import { ChatService } from "./chat.service";
import { NotificationService } from "./notification.service";
import { BodyService } from "./body.service";
import { LayoutService } from "./layout.service";
import { SoundService } from "./sound.service";
import { BrokerService } from "./broker.service";
import { EaService } from "./ea.service";
import { SubscriptionService } from "./subscription.service";
import { Mt4AccountService } from "./mt4account.service";
import { AdminUserService } from "./adminuser.service";
import { SymbolService } from "./symbol.service";
import { SpreadService } from "./spread.service";
import { MemberGroupService } from "./membergroup.service";
import { MarkupService } from "./markup.service";
import { ApplicationService } from "./application.service";
import { NatureOfBusinessService } from "./natureofbusiness.service";
import { MemberService } from "./member.service";
import { ProductService } from "./product.service";
import { OrderUnitService } from "./orderunit.service";
import { RoleService } from "./role.service";
import { PriceBufferService } from "./pricebuffer.service";
import { WeekService } from "./week.service";
import { BookSchedulerService } from "./bookscheduler.service";
import { BookModeService } from "./bookmode.service";
import { OrderService } from "./order.service";
import { OrderDeliveryService } from "./orderdelivery.service";
import { ResidueService } from "./residue.service";
import { SignalRService } from "./signalr.service";
import * as fromVoice from './voice'

export const services = [
  StorageService,
  AuthTokenService,
  AuthService,
  TokenInterceptor,
  JsonApiService,
  UserService,
  CountryService,
  ChatService,
  NotificationService,
  BodyService,
  LayoutService,
  SoundService,
  fromVoice.VoiceControlService,
  fromVoice.VoiceRecognitionService,
  BrokerService,
  EaService,
  SubscriptionService,
  Mt4AccountService,
  AdminUserService,
  SymbolService,
  SpreadService,
  MemberGroupService,
  MarkupService,
  ApplicationService,
  NatureOfBusinessService,
  MemberService,
  ProductService,
  OrderUnitService,
  RoleService,
  PriceBufferService,
  WeekService,
  BookSchedulerService,
  BookModeService,
  OrderService,
  OrderDeliveryService,
  ResidueService,
  SignalRService
];

export * from "./storage.service";
export * from "./auth-token.service";
export * from "./auth.service";
export * from "./token.interceptor";
export * from "./json-api.service";
export * from "./user.service";
export * from "./country.service";
export * from "./chat.service";
export * from "./notification.service";
export * from "./body.service";
export * from "./layout.service";
export * from "./sound.service";
export * from "./voice";
export * from "./broker.service";
export * from "./ea.service";
export * from "./subscription.service";
export * from "./mt4account.service";
export * from "./adminuser.service";
export * from "./symbol.service";
export * from "./spread.service";
export * from "./membergroup.service";
export * from "./markup.service";
export * from "./application.service";
export * from "./natureofbusiness.service";
export * from "./member.service";
export * from "./product.service";
export * from "./orderunit.service";
export * from "./role.service";
export * from "./pricebuffer.service";
export * from "./week.service";
export * from "./bookscheduler.service";
export * from "./bookmode.service";
export * from "./order.service";
export * from "./orderdelivery.service";
export * from "./residue.service";
export * from "./signalr.service";
