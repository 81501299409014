import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from "@angular/http";
import { BehaviorSubject } from "rxjs";
import { JsonApiService } from "@app/core/services/json-api.service";
import { Observable } from "rxjs/Observable";
import { SymbolSpread } from "@app/models/symbol/symbolspread.model";
import { IResponseMessage } from "@app/models/responsemessage.model";
import { SymbolPricingModel } from "@app/models/symbol/symbolpricing.model";
import { Config } from "@app/config/config";

const config = new Config();
@Injectable()
export class SymbolService {
    readonly rootUrl = config.webAPIUrl + '/api';

    constructor(private jsonApiService: JsonApiService, private http: HttpClient) {
    }

    getCurrentPricing(symbolIds: string) : Observable<SymbolPricingModel[]> {
        return this.http.get<SymbolPricingModel[]>(this.rootUrl + '/symbol/spotprice/' + symbolIds);
    }

    getAll(): Observable<IResponseMessage> {
        return this.http.get<IResponseMessage>(this.rootUrl + '/symbol/getsymbolspread');
    }
}
