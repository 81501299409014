import { Injectable, Injector } from '@angular/core';
import { Router } from "@angular/router";
import 'rxjs/add/operator/do';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthTokenService } from './auth-token.service';

import { environment } from '@env/environment';
import { Store } from '@ngrx/store';

import * as fromAuth from '../store/auth';
import { catchError } from 'rxjs/operators';
import { Config } from "@app/config/config";
import { UserService } from '@app/core/services/user.service';

import { NotificationService } from '@app/core/services';

const config = new Config();
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  readonly rootUrl = config.webAPIUrl;
  constructor(
    private injector: Injector,
    public authToken: AuthTokenService,
    public store: Store<fromAuth.AuthState>,
    private router: Router,
    // private notificationService: NotificationService,
    private userService: UserService,


  ) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.headers.get('No-Auth') == "True") {
      return next.handle(request.clone());
    }

    if (localStorage.getItem(this.rootUrl + 'goldPro_memberToken') != null) {
      const clonedreq = request.clone({
        headers: request.headers.set("Authorization", "Bearer " + localStorage.getItem(this.rootUrl + 'goldPro_memberToken'))
      });
      return next.handle(clonedreq)
        .do(
          succ => { },
          err => {
            if (err.status === 401) {
              localStorage.removeItem(this.rootUrl + 'goldPro_memberToken');
              localStorage.removeItem(this.rootUrl + 'GoldPro_Member');
              localStorage.removeItem(this.rootUrl+'goldPro_memberGroup');
              this.userService.setIsUserLoggedIn(false);
              this.router.navigateByUrl('auth/login');
            }
            else if (err.status === 406) {
              if (this.userService.isUserLoggedIn()) {
                this.userService.setIsUserLoggedIn(false);
                localStorage.removeItem(this.rootUrl + 'goldPro_memberToken');
                localStorage.removeItem(this.rootUrl + 'GoldPro_Member');
                localStorage.removeItem(this.rootUrl+'goldPro_memberGroup');
                this.router.navigateByUrl('auth/login');
                $.SmartMessageBox({
                  title: "Warning",
                  content: "You have signed in from another device.",
                  buttons: '[Ok]'
                }, (ButtonPressed) => {
                  if (ButtonPressed === "Ok") {
                   
                  }
                });
              }
            }
          }
        );
    }
    else {
      this.router.navigateByUrl('auth/login');
    }

    if (request.url.search('YOUR_API_ENDPOINT') === 0) {
      // attach tcken
      return this.handleApiRequest(request, next);
    } else {
      return next.handle(request);
    }
  }



  handleApiRequest(request, next) {
    request = this.authToken.token
      ? request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authToken.token}`
        }
      })
      : request;

    const handler = next.handle(request).pipe(
      catchError((error, caught) => {
        if (error.status === 401 || error.status === 403) {
          this.store.dispatch(new fromAuth.LogoutAction());
          return throwError(error);
        }
        else {
          return throwError(error);
        }
      })
    );

    return handler;
  }
}
