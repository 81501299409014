import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { MainLayoutComponent } from "./shared/layout/app-layouts/main-layout.component";
import { AuthLayoutComponent } from "./shared/layout/app-layouts/auth-layout.component";
import { AuthGuard } from '@app/core/guards/auth.guard';

const routes: Routes = [
  {
    path: "",
    component: MainLayoutComponent,
    canActivate:[AuthGuard],
    data: { pageTitle: "Home" },
    children: [     
      {
        path: "",
        redirectTo: "/trade/list",
        pathMatch: "full"
      },
      {
        path: "dashboard",
        loadChildren: "./features/dashboard/dashboard.module#DashboardModule",
        data: { pageTitle: "Mt4Account" }
        },
      {
        path: "trade",
        loadChildren: "./features/trade/trade.module#TradeModule",
        data: { pageTitle: "Trade" }
        },
        {
            path: "spotprice",
            loadChildren: "./features/spotprice/spotprice.module#SpotPriceModule",
            data: { pageTitle: "Spot Price" }
        },
        {
            path: "account",
            loadChildren: "./features/account/account.module#AccountModule",
            data: { pageTitle: "Account" }
        },
      {
        path: "product",
        loadChildren: "./features/product/product.module#ProductModule",
        data: { pageTitle: "Product" }
      },
      {
        path: "report",
        loadChildren: "./features/report/report.module#ReportModule",
        data: { pageTitle: "Report" }
      },
      {
        path: "customerstatement",
        loadChildren: "./features/customerstatement/customerstatement.module#CustomerStatementModule",
        data: { pageTitle: "Report" }
      },
      {
        path: "profile",
        loadChildren: "./features/profile/profile.module#ProfileModule",
        data: { pageTitle: "Profile" }
      }
    ]
  },
  {
    path: "auth",
    component: AuthLayoutComponent,
    loadChildren: "./features/auth/auth.module#AuthModule"
  },
  { path: "**", redirectTo: "miscellaneous/error404" }


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
