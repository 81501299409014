import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from "@angular/http";
import { BehaviorSubject } from "rxjs";
import { JsonApiService } from "@app/core/services/json-api.service";
import { Observable } from "rxjs/Observable";
import { map } from 'rxjs/operators';
import { Config } from "@app/config/config";
import { IResponseMessage } from "@app/models/responsemessage.model";
const config = new Config();

@Injectable()
export class BookModeService {
  readonly rootUrl = config.webAPIUrl + '/api';

  constructor(private jsonApiService: JsonApiService, private http: HttpClient) {
  }

  getall(): Observable<IResponseMessage> {
    return this.http.get<IResponseMessage>(this.rootUrl + '/bookmode/list/');
  }

  activate(bookmodeid: number): Observable<object> {
    return this.http.post(this.rootUrl + '/bookmode/manual/'+bookmodeid, {});
  }

  islimitpriceenabled(): Observable<object> {
    return this.http.get<IResponseMessage>(this.rootUrl + '/bookmode/islimitpriceenabled/');
  }
}
