import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import * as fromAuth from '../store/auth';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Config } from "@app/config/config";
import { UserService } from '@app/core/services/user.service';

const config = new Config();


@Injectable()
export class AuthGuard implements CanActivate {
  readonly rootUrl = config.webAPIUrl;
  constructor(private router: Router, private userService : UserService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (localStorage.getItem(this.rootUrl + 'goldPro_memberToken') != null)
    {
      this.userService.setIsUserLoggedIn(true);
      return true;
    }
     
    this.router.navigate(['auth/login']);
    return false;
  }
}

//  export class AuthGuard implements CanActivate {
//    constructor(
//      private store: Store<fromAuth.AuthState>, private router: Router) {
//      }

//    canActivate(): Observable<boolean> {

//      return this.store.pipe(
//        select(fromAuth.getLoggedIn),
//        map(authed => {
//          if (!authed) {
//            //this.router.navigate(['/auth/login']);
//            //this.store.dispatch(new fromAuth.LoginRedirect('/dashboard'));
//            alert();
//            return false;
//          }

//          return true;
//        }),
//        take(1)
//      );
//    }
//  }
