import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { Config } from "@app/config/config";
const config = new Config();

@Injectable()
export class AuthService {
  readonly rootUrl = config.webAPIUrl;
  isLoggedIn: boolean = false;

  // store the URL so we can redirect after logging in
  redirectUrl: string;

  login(): Observable<boolean> {
    return of(true)
      .pipe(
        delay(1000),
        tap(val => this.isLoggedIn = true)
      )

  }

  logout(): void {
    localStorage.removeItem(this.rootUrl+'goldPro_memberToken');
    localStorage.removeItem(this.rootUrl+'goldPro_memberGroup');
    this.isLoggedIn = false;
  }
}
