import { Component, OnInit } from "@angular/core";
import { routerTransition } from "@app/shared/utils/animations";
import { MemberService } from '@app/core/services/member.service';
import { UserService } from '@app/core/services/user.service';
import { Config } from "@app/config/config";
const config = new Config();
@Component({
  selector: "app-main-layout",
  templateUrl: "./main-layout.component.html",
  styles: [],
  animations: [routerTransition]
})


export class MainLayoutComponent implements OnInit {
  readonly rootUrl = config.webAPIUrl;
  constructor(private userService: UserService) { }
  ngOnInit() {

    if (this.userService.isUserLoggedIn()) {
      this.userService.IsAuthenticated().subscribe((data: any) => {
        if (data.Success) {
            var singleDeviceSignInCheckingTimeinSecond = data.Data
            setInterval(() => {
              if (this.userService.isUserLoggedIn()) {
                this.userService.IsAuthenticated().subscribe((data: any) => {
                  if (data.Success) {
                    singleDeviceSignInCheckingTimeinSecond = data.Data
                  }
              });
              }
            }, singleDeviceSignInCheckingTimeinSecond * 1000);
        }
    });
    }

   
  }

  getState(outlet) {
    if (!outlet.activatedRoute) return;
    let ss = outlet.activatedRoute.snapshot;

    // return unique string that is used as state identifier in router animation
    return (
      outlet.activatedRouteData.state ||
      (ss.url.length
        ? ss.url[0].path
        : ss.parent.url.length
          ? ss.parent.url[0].path
          : null)
    );
  }
}
